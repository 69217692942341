<script setup lang="ts">
const localePath = useLocalePath();
const { formatLink } = useInternationalization(localePath);

function openSecondLayer() {
  // @ts-expect-error no types for UC_UI
  window.UC_UI.showSecondLayer();
}
</script>

<template>
  <div class="flex justify-center items-center bg-scheppach-primary-700">
    <div class="hidden xl:block">&nbsp;</div>

    <div class="flex flex-col justify-center items-center w-full">
      <div
        class="flex flex-col lg:flex-row justify-between items-center w-full gap-8 mt-4 xl:my-6 mb-3"
      >
        <NuxtLink :to="formatLink(`/`)">
          <img
            class="h-10 xl:h-14.5"
            src="/logo-scheppach-white.svg"
            alt="logo of the shop"
          />
        </NuxtLink>
        <div
          class="flex flex-wrap items-center justify-center text-14px xl:text-16px gap-5 c-white underline-offset-5"
        >
          <NuxtLink :to="formatLink($t('urls.tncs'))" class="hover:underline">
            {{ $t("footer.terms-of-service") }}
          </NuxtLink>
          <NuxtLink
            :to="formatLink($t('urls.privacy-policy'))"
            class="hover:underline"
          >
            {{ $t("footer.data-protection") }}
          </NuxtLink>
          <NuxtLink
            to="#"
            class="cursor-pointer hover:underline"
            @click="openSecondLayer"
          >
            {{ $t("footer.cookies") }}
          </NuxtLink>
          <NuxtLink
            :to="formatLink($t('urls.imprint'))"
            class="hover:underline"
          >
            {{ $t("footer.imprint") }}
          </NuxtLink>
          <NuxtLink
            :to="formatLink($t('urls.return-policy'))"
            class="hover:underline"
          >
            {{ $t("footer.return-policy") }}
          </NuxtLink>
          <NuxtLink
            :to="$t('urls.shipping-policy')"
            class="hover:underline underline-offset-5"
            >{{ $t("checkout.summaryBox.shipment") }}
          </NuxtLink>
        </div>
      </div>
    </div>

    <!-- <div class="hidden xl:block">
      <LanguageSwitcher class="rd" />
    </div> -->
  </div>
</template>
